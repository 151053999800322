/* global GATSBY_THEME_I18N_LINGUI */
const React = require('react');
const i18n = require('@lingui/core').i18n;
const I18nProvider = require('@lingui/react').I18nProvider;

const wrapPageElement = ({ element, props }) => {
  const locale = props.pageContext.locale;
  const catalog = require(`${GATSBY_THEME_I18N_LINGUI}/${locale}/messages.js`);

  i18n.load(locale, catalog.messages);
  i18n.activate(locale);

  return <I18nProvider i18n={i18n}>{element}</I18nProvider>;
};

module.exports = wrapPageElement;
