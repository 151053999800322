exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-game-tsx": () => import("./../../../src/pages/game.tsx" /* webpackChunkName: "component---src-pages-game-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invite-tsx": () => import("./../../../src/pages/invite.tsx" /* webpackChunkName: "component---src-pages-invite-tsx" */),
  "component---src-pages-wiki-values-tsx": () => import("./../../../src/pages/wiki/values.tsx" /* webpackChunkName: "component---src-pages-wiki-values-tsx" */)
}

